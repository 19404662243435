import React from 'react';
import FeatureShowcase from '../../components/home/FeatureShowcase';
import notionIcon from '../../images/alternative-to/notionIcon.svg';
import notionLogo from '../../images/alternative-to/notionLogo.png';
import collaborateIcon from '../../images/feature-icons/collaborate-icon.svg';
import integrateIcon from '../../images/feature-icons/integrate.svg';
import organizationIcon from '../../images/feature-icons/organize-icon.svg';
import retrieveIcon from '../../images/feature-icons/retrieve-icon.svg';
import integrateIllustration from '../../images/home/features/integrate.svg';
import organizeIllustration from '../../images/home/features/organize.svg';
import retrieveIllustration from '../../images/home/features/retrieve.svg';
import verifyIllustration from '../../images/home/features/verify.svg';
import ComparisonTemplate from '../../templates/ComparisonTemplate';

const Page: React.SFC = (props) => (
  <ComparisonTemplate
    competitor={{
      name: 'Notion',
      icon: notionIcon,
      fullLogo: notionLogo,
    }}
    metaTitle="Alternative to Notion for Knowledge Sharing - Slab"
    metaDescription="With Slab, your team can organize, discover, and search valuable company knowledge."
    header="Why go with Slab vs Notion?"
    subheader="Teams choose Slab to scale beyond Notion for team knowledge sharing. Organization features, ease of use, and integrations make Slab the #1 choice."
    features={
      <>
        <FeatureShowcase
          icon={collaborateIcon}
          title="Intuitive"
          headline="Easy to use for teammates"
          description="Easily create, organize, and discover knowledge for all your teammates in any department — whether non-technical, tech-savvy, or new to the team."
          illustration={organizeIllustration}
          remarkLogo={notionIcon}
          remark="As content grows on Notion, it becomes increasingly hard to manage or for new teammates to search, discover, or contribute."
        />
        <FeatureShowcase
          icon={organizationIcon}
          title="Verification"
          headline="Trust your team knowledge"
          description="Verification by a designated maintainer ensures team knowledge is regularly reviewed, updated, and trusted, so teammates can be confident in what they find."
          illustration={verifyIllustration}
          reversed
          remarkLogo={notionIcon}
          remark="In Notion, teammates are left guessing whether content is stale or outdated."
        />
        <FeatureShowcase
          icon={retrieveIcon}
          title="Search"
          headline="Not yet another place to search"
          description="With Unified Search, we'll pull answers from your Slab content and all of your integrated tools, bringing you the most relevant results — all in one place."
          illustration={retrieveIllustration}
          remarkLogo={notionIcon}
          remark="Notion lacks functionality to search integrations' contents."
        />
        <FeatureShowcase
          icon={integrateIcon}
          title="Integrate"
          headline="Integrate with tools you choose"
          description="Slab is a best-in-class product that isn't trying to do it all. That's why it offers first-class integrations with everything else, so you can choose the best tools for the particular job and have them seamlessly work with Slab."
          illustration={integrateIllustration}
          reversed
          remarkLogo={notionIcon}
          remark="Notion aims to be an all-in-one tool and lacks integrations with other productivity tools."
        />
      </>
    }
    comparisons={[
      {
        title: 'Unlimited posts',
        description: 'No limit to the quanity or length.',
        slab: { supported: true },
        competitor: { supported: true },
      },
      {
        title: 'Version History',
        description: 'Full history of changes on posts.',
        slab: { supported: true },
        competitor: { supported: true },
      },
      {
        title: 'Flexible Organization',
        description: 'First class tools for organization.',
        slab: { supported: true },
        competitor: { supported: false },
      },
      {
        title: 'Unified Search',
        description: 'Search across native content and integrations.',
        slab: { supported: true },
        competitor: { supported: false },
      },
      {
        title: 'Verification',
        description: 'Avoid stale and outdated knowledge.',
        slab: { supported: true },
        competitor: { supported: false },
      },
      {
        title: 'Analytics',
        description:
          'Insights into team usage, contribution, and engagement teamwide.',
        slab: { supported: true },
        competitor: { supported: false },
      },
    ]}
    {...props}
  />
);

export default Page;
